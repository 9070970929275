import React from "react";
import ProductTemplate from "./product";
import NewProductTemplate from "../../components/sections/product-more-info/Item";

const Product = props => {
    const { pageContext } = props;
    const { item } = pageContext;

    const newProductsEnabled = process.env.GATSBY_NEW_PRODUCTS_ENABLED === "true";

    const Data = () => {
        return (
            <>
                <p>
                    <strong>
                        L’Èxit no és fruit de la casualitat sinó una conseqüència de l’esforç.
                    </strong>{" "}
                    Per tant, si tens aquest llibre a les teves mans no és fruit d’una decisió
                    aleatòria sinó que neix d’un desig molt concret d’aconseguir un{" "}
                    <strong>SOMNI</strong>. El somni de formar part de les Forces i Cossos de
                    Seguretat:
                </p>

                <p>* Les forces i cossos de seguretat de l'Estat dependents del Govern central.</p>

                <p>
                    * Els Cossos de Policia dependents de les Comunitats Autònomes, fent especial
                    atenció en el cos de <strong>MOSSOS D’ESQUADRA</strong>.
                </p>

                <p>
                    * Els Cossos de Policia dependents de les Corporacions Locals, fent especial
                    atenció en el cos de la <strong>GUÀRDIA URBANA DE BARCELONA</strong>.
                </p>

                <p>
                    Aquest llibre és per tant, una eina imprescindible per aconseguir dominar la
                    prova de psicotècnics aptitudinals.
                </p>

                <p>
                    Per tal de facilitar l’aprenentatge i la integració dels coneixements
                    necessaris, s’ha estructurat tot el llibre en 5 raonaments (espacial, abstracte,
                    verbal, numèric i visual) més les possibilitats de posar-te a prova amb 4
                    simulacres basats en les proves oficials de Mossos d’Esqudra i Guàrdia Urbana de
                    Barcelona.
                </p>

                <p>
                    Esperem doncs que aquest llibre et sigui de molta utilitat i aconsegueixis el
                    teu somni de formar part d’aquesta gran família, de la qual ens sentim tant
                    orgullosos.
                </p>

                <p>Fins aviat company!</p>

                <p>
                    <strong>Frase per vendre el llibre:</strong>{" "}
                    <em>
                        Un llibre dissenyat per ajudar-te a dominar les proves de psicotècnics, i
                        aconseguir el teu somni de formar part de les Forces i Cossos de Seguretat.
                        Preparat, superat i triomfa!
                    </em>
                </p>

                <div className="rounded p-5 border shadow-md mt-5">
                    <embed
                        src={require("@static/documents/products/205/demo_psicos.pdf")}
                        type="application/pdf"
                        className=""
                        width="100%"
                        height="750px"
                    ></embed>
                </div>

                <div className="mt-10">
                    Si no veus correctament el pdf pots fer clic en aquest{" "}
                    <a
                        className="border-b"
                        target="_blank"
                        rel="noopener noreferrer"
                        href={require("@static/documents/products/205/demo_psicos.pdf")}
                    >
                        enllaç
                    </a>
                    .
                </div>
            </>
        );
    };

    return (
        <>
            {!newProductsEnabled ? (
                <ProductTemplate item={item}>
                    <Data />
                </ProductTemplate>
            ) : (
                <NewProductTemplate
                    item={item}
                    title="Un llibre dissenyat per ajudar-te a dominar les proves de psicotècnics, i aconseguir el teu somni de formar part de les Forces i Cossos de Seguretat. Preparat, superat i triomfa!"
                >
                    <Data />
                </NewProductTemplate>
            )}
        </>
    );
};

export default Product;

/*import React from "react";
import ProductTemplate from "./product";
import Title from "@components/common/Title";
import Text from "@components/common/Text";

const Product = props => {
    const { pageContext } = props;
    const { item } = pageContext;

    return (
        <ProductTemplate item={item}>
            
            <div className="container lg:px-0 py-20">
                <Title>El Llibre</Title>

                <Text>
                    L’Èxit no és fruit de la casualitat sinó una conseqüència de l’esforç. Per tant
                    si tens aquest llibre a les teves mans no és fruit d’una decisió aleatòria sinó
                    que neix d’un desig molt concret d’aconseguir un SOMNI. El somni de formar part
                    de les Forces i Cossos de Seguretat:
                    <br />
                    <br />
                    * Les forces i cossos de seguretat de l'Estat dependents del Govern central.
                    <br />
                    <br />
                    * Els Cossos de Policia dependents de les Comunitats Autònomes, fent especial
                    atenció en el cos de MOSSOS D’ESQUADRA.
                    <br />
                    <br />
                    * Els Cossos de Policia dependents de les Corporacions Locals, fent especial
                    atenció en el cos de la GUÀRDIA URBANA DE BARCELONA.
                    <br />
                    <br />
                    Aquest llibre és per tant, una eina imprescindible per aconseguir dominar la
                    prova de psicotècnics aptitudinals.
                    <br />
                    <br />
                    Per tal de facilitar l’aprenentatge i la integració dels coneixements
                    necessaris, s’ha estructurat tot el llibre en 5 raonaments ( espacial,
                    abstracte, verbal, numèric i visual ) més las possibilitat de posar-te a prova
                    amb 4 simulacres basats en les proves oficials de Mossos d’Esqudra i Guàrdia
                    Urbana de Barcelona.
                    <br />
                    <br />
                    Esperem doncs que aquest llibre et sigui de molta utilitat i aconsegueixis el
                    teu somni de formar part d’aquesta gran familia, de la qual ens sentim tant
                    orgullosos.
                    <br />
                    <br />
                    Fins aviat company!
                    <br />
                    <br />
                    <br />                    
                </Text>
                
                <div className="container lg:px-0 py-20">
                    <Title>Preview del libro</Title>
                    <Text className="text-justify">
                        A continuació us ensenyem un extracte variat del llibre.
                    </Text>

                    <div className="rounded p-5 border shadow-md mt-5">
                        <embed
                            src={require("@static/documents/products/205/demo_psicos.pdf")}
                            type="application/pdf"
                            className=""
                            width="100%"
                            height="750px"
                        ></embed>
                    </div>
                    <div className="mt-10">
                        Si no veus correctament el pdf pots fer clic en aquest{" "}
                        <a
                            className="border-b"
                            target="_blank"
                            rel="noopener noreferrer"
                            href={require("@static/documents/products/205/demo_psicos.pdf")}
                        >
                            enllaç
                        </a>
                        .
                    </div>
                </div>
            </div>
        </ProductTemplate>
    );
};

export default Product;*/
